import React, { useState } from "react";

const Contactus = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({ name: false, email: false, message: false });
  const [submissionError, setSubmissionError] = useState(false);

const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {
      name: !name,
      email: !email,
      message: !message
    };
    setErrors(newErrors);
    if (newErrors.name || newErrors.email || newErrors.message) {
      return;
    }
    try {
      const mailtoLink = `mailto:admin@gmail.com?subject=Contact Us Form&body=Full Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
      window.location.href = mailtoLink;
    } catch (error) {
      setSubmissionError(true);
    }
  };


  return (
    <div className="form-container">
      <section>
        <div className="section-header">
          <div className="container">
            <h2>Contact Us</h2>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="contact-form">
              <form id="contact-form" onSubmit={handleSubmit}>
                <div className="input-box">
                  <input
                    type="text"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label className={name ? "filled" : ""}>Full Name</label>
                  {errors.name && <span className="error-text">Full Name is required</span>}
                </div>

                <div className="input-box">
                  <input
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className={email ? "filled" : ""}>Email</label>
                  {errors.email && <span className="error-text">Email is required</span>}
                </div>

                <div className="input-box">
                  <textarea
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={3}
                  ></textarea>
                  <label className={message ? "filled" : ""}>Type your Message...</label>
                  {errors.message && <span className="error-text">Message is required</span>}
                </div>

                <div className="input-box">
                  <input type="submit" value="Send" style={{ marginTop: '-50px' }} />
                </div>
                {submissionError && (
                <div className="error-message">
                  Unable to open mail client. Please contact us at <a href="mailto:admin@gmail.com">admin@gmail.com</a>.
                </div>
              )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contactus;
